<template>
  <div :class="{'modal modal_wrap': modalView}">
    <div :class="{'modal_popup modal_content': modalView}">
      <div :class="{'modal__context': modalView}">
        <section class="item-types">
          <breadcrumbs v-if="!modalView && !isEdit"></breadcrumbs>
          <h2 class="titlePage_subtitle titlePage">WHEELS</h2>
          <span class="modal__close" v-if="modalView" @click="closePopup">
             <svg-icon name="times"/>
          </span>
          <div class="row">
            <div class="col-sm-6 col-md-4 col-12"
                 v-for="item in getDefaultChoices"
                 :key="item.id"
                 >
              <label class="form__label form__label_row">
                <input type="radio" hidden="hidden"
                       class="form__radio"
                       name="mesh"
                       v-bind:checked="item.default"
                       @change="selectOption(item)"
                >
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">{{item.name}}</span>
              </label>
            </div>

            <div class="col-sm-6 col-md-4 col-12"
                 v-for="item in getNonDefaultChoices"
                 :key="item.id"
                 >
              <label class="form__label form__label_row">
                <input type="radio" hidden="hidden"
                       class="form__radio"
                       name="mesh"
                       @change="selectOption(item)"
                       v-bind:checked="item.default"
                >
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">{{item.name}}</span>
              </label>
            </div>
          </div>
          <template v-if="actionLinksPortalTarget">
            <portal :to="actionLinksPortalTarget">
              <button type="button" class="btn btn_transparent" v-if="!isUpdate" @click.prevent="next"><svg-icon name="solid/step-forward" /> next</button>
              <button type="button" class="btn btn_transparent" v-else @click.prevent="next"><svg-icon name="solid/check" /> done</button>
            </portal>
          </template>
          <template v-else>
            <button type="button" class="btn btn_default" v-if="!isUpdate" @click.prevent="next"><svg-icon name="solid/step-forward" /> next</button>
            <button type="button" class="btn btn_default" v-else @click.prevent="next"><svg-icon name="solid/check" /> done</button>
          </template>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import {defineAsyncComponent} from "vue";

  const breadcrumbs = defineAsyncComponent(() => import("./../../parts/breadcrumbs"));
  import {calcSimpleOptionPrice} from "../../../helpers/calcPriceOption";
  import {changePriceForm} from "../../../helpers/aroundPrice";
  import {mapState, mapGetters} from 'vuex';

  export default {
      name: "Wheel",
      data() {
          return {
              selection: {},
          }
      },
      components: {
          breadcrumbs
      },
      props: ['options', 'package', 'set', 'checkIsSetPackage', 'actionLinksPortalTarget'],
      methods: {
          selectOption(item) {
              const backup = _.cloneDeep(this.$store.getters['orderPosition/getCurrentOptionSettings']);

              _.forEach(this.selection.groups.default.choices, (i, k) => {
                  i.default = item.name == i.name;
              });

              if(this.isEdit) {
                  // const backup = _.cloneDeep(this.selection)
                  const rollbackHandler = () => {
                      this.$store.commit('orderPosition/setOption', {
                          option: 'WHL',
                          selection: backup
                      });
                  }
                  this.$store.dispatch('orderPosition/trackChanges', rollbackHandler)
              }

              this.$store.commit('orderPosition/setOption', {option: 'WHL', selection: this.selection});
          },

          next() {
              if(this.isEdit){
                  const backup = _.cloneDeep(this.$store.getters['orderPosition/getCurrentOptionSettings']);
                  const rollbackHandler = () => {
                      this.$store.commit('orderPosition/setOption', {
                          option: 'WHL',
                          selection: backup
                      });
                  }
                  this.$store.dispatch('orderPosition/guessNextStep', rollbackHandler)
              }else {
                  this.$store.commit('orderPosition/optionNext', 'WHL');
              }
              // this.$store.commit('orderPosition/optionNext', 'WHL');

          },
      closePopup() {
        this.$store.commit('options/closeAvailable');
      },
    },
    computed: {
      ...mapState('options', ['name', 'price', 'modalView', 'priceAllOptions']),
      ...mapState('orderItem', ['isUpdate']),
      ...mapGetters({
        isEdit: 'orderPosition/isCompleted',
      }),
      getDefaultChoices () {
        return _.filter(this.selection.groups.default.choices,(item) => {
          return item.is_default
        })
      },
      getNonDefaultChoices () {
        return _.filter(this.selection.groups.default.choices,(item) => {
          return !item.is_default
        })
      }

    },

    created() {
        this.selection = this.$store.getters['orderPosition/getCurrentOptionSettings'];
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .form {
    &__label {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &__input {
      border: none;
      border-bottom: 2px solid $navy;
    }

    &__labelTitle {
      color: $navy;
    }
  }

  .text_white {
    position: absolute;
    bottom: 3rem;
    left: auto;
    right: 2rem;
    display: inline-block;
    width: auto;
  }

  .modal__close {
    color: $green;
  }
</style>
